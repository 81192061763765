import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { navigate } from "gatsby"

const NavigateBack = () => (
  <Wrapper>
    <button
      onClick={() => {
        navigate(-1)
      }}
    >
      ← Indietro
    </button>
  </Wrapper>
)
const Wrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  button {
    border: 2px solid ${DesignTokens.colors.primary[500]};
    background: white;
    padding: 0.25rem 1rem;
    &:hover {
      cursor: pointer;
      background-color: ${DesignTokens.colors.primary[500]};
      color: white;
    }
  }
`

export default NavigateBack
