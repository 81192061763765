import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import Img from "gatsby-image"
import { Display, Lead } from "../components/typography"
import RichContentTextBlock from "../components/richContentTextBlock"
import PageTitle from "../components/pageTitle"
import Gut from "../components/gut"
import NavigateBack from "../components/navigateBack"
import { Helmet } from "react-helmet"
import { HelmetDatoCms } from "gatsby-source-datocms"

const NewsPage = ({ data: { news } }) => {
  // console.log(news)
  return (
    <Layout>
      <SEO title={news.title} />
      <Helmet>
        <meta property="og:image" content={news.image.url} />
      </Helmet>
      <HelmetDatoCms seo={news.seoMetaTags} />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <NavigateBack />
            </Col>
          </Row>
          <Row>
            <Col>
              <PageTitle>
                <Display>{news.title}</Display>
                <Lead>{news.date}</Lead>
              </PageTitle>
            </Col>
          </Row>
          <Gut gutter={4}>
            <Row>
              <Col>
                <Img fluid={news.image.fluid} />
              </Col>
            </Row>
            <Row justify="center">
              <Col md={8}>
                <RichContentTextBlock html={news.text} />
              </Col>
            </Row>
          </Gut>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default NewsPage

export const query = graphql`
  query NewsQuery($originalId: String!) {
    news: datoCmsNews(originalId: { eq: $originalId }) {
      title
      slug
      text
      date(locale: "it", formatString: "D MMMM YYYY")
      summary
      image {
        fluid(maxWidth: 980) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
